import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createI18n } from 'vue-i18n'
import i18nData from './i18n/i18nData'

const i18n = createI18n({
  locale: 'en', // set default locale
  messages: i18nData
})

createApp(App).use(i18n).use(router).mount('#app')
